import React, { useEffect, useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import reducerSlice, { setOtp } from "./redux/Reducer";
import ButtonGreen from "../../../components/ButtonGreen/ButtonGreen";
import { t } from "react-multi-lang";
import StoreHelper from "../../../services/store-helper";
import Action from "./redux/Action";

const OtpInput = React.forwardRef(
  ({ value, onChange, onEnter, errorOtp }, ref) => (
    <input
      ref={ref}
      type="text"
      maxLength="1"
      value={value}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.key === "Enter" && onEnter) {
          onEnter();
        }
      }}
      onFocus={(e) => {
        e.target.select();
      }}
      className={`text-center rounded-lg w-[50px] h-[70px]
        ${
          errorOtp
            ? "border-2 border-red-600 ring-red-600"
            : "border-0 focus:border-gold-primary focus:ring-gold-primary"
        }        
        focus:outline-none focus:ring text-3xl font-bold`}
    />
  )
);

const AuthenticatorCode = ({ onSubmit }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const dispatch = useDispatch();
    const inputRefs = [
      useRef(),
      useRef(),
      useRef(),
      useRef(),
      useRef(),
      useRef(),
    ];
    const otp = useSelector((state) => state.reducer.otp); // Pastikan reducer sudah terdaftar
    const errorOtp = useSelector((state) => state.reducer.errorOtp);

    const handleSubmit = () => {
      Action.validate2faCode(dispatch, otp);
    };
    return (
      <div className="section py-10 px-6 flex flex-col">
        <div className="flex flex-col mb-10">
          <h2 className="text-green-secondary font-bold text-2xl mb-4">
            {t("login.authenticatorcode")}
          </h2>
          <p className="text-gray-500 mb-5">
            {t("login.subtitleauthenticatorcode")}
          </p>

          <div className="grid grid-cols-6 text-black gap-y-4 my-6 px-7">
            {otp &&
              otp.map((digit, i) => (
                <OtpInput
                  key={i}
                  value={digit}
                  ref={inputRefs[i]}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newOtp = [...otp];
                    newOtp[i] = value;
                    dispatch(setOtp(newOtp));

                    // Pindah fokus ke input berikutnya jika ada input
                    if (value !== "" && i < inputRefs.length - 1) {
                      inputRefs[i + 1].current.focus();
                    }
                  }}
                  errorOtp={errorOtp}
                  onEnter={i === otp.length - 1 ? handleSubmit : null}
                />
              ))}
          </div>

          {errorOtp && <p className="text-red-600 mb-4">{errorOtp}</p>}
        </div>

        <div className="flex items-center justify-end ml-0">
          <ButtonGreen title={t("login.verify")} onClick={handleSubmit} />
        </div>
      </div>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default AuthenticatorCode;
