import { useEffect, React } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { setTranslations, setDefaultLanguage } from "react-multi-lang";
import AppLayout from "../../components/layout/applayout/AppLayout";
import AuthLayout from "../../components/layout/authlayout/AuthLayout";
import RouteName from "../../services/routename";
import StoreHelper from "../../services/store-helper";
import AccountSettings from "../accountsettings/AccountSettings";
import AppCustomization from "../appcustomization/AppCustomization";
import UseAuth from "../auth/Auth";
import ForgotPassword from "../auth/forgotpassword/ForgotPassword";
import Login from "../auth/login/Login";
import NewPassword from "../auth/newpassword/NewPassword";
import Dashboard from "../dashboard/Dashboard";
import GenerateQR from "../generateqr/GenerateQR";
import HelpCenter from "../helpcenter/HelpCenter";
import Management from "../management/Management";
import NotFound from "../notfound/NotFound";
import reducerSlice from "./redux/Reducer";
import en from "../../translations/en.json";
import id from "../../translations/id.json";
import Action from "./redux/Action";
import Privacy from "../privacy/Privacy";
import Cookies from "js-cookie";
import Balance from "../balance/Balance";
import ContactUs from "../contactus/ContactUs";
import PromoForm from "../management/component/promo/component/form/Form";
import FreezeMode from "../freezemode/FreezeMode";
import ScanQR from "../auth/twofactorauth/ScanQR";
import AuthenticatorCode from "../auth/twofactorauth/AuthenticatorCode";
import AuthForgotPassword from "../auth/forgotpassword/AuthenticatorCode";
import ScanQRPassword from "../auth/forgotpassword/ScanQRPassword";

const MainComponent = () => {
  const translation = useSelector((state) => state.reducer.translation);
  const dispatch = useDispatch();
  const isAuthenticated = UseAuth.getRole();
  setTranslations({ en, id });
  setDefaultLanguage(translation);

  useEffect(() => {
    Cookies.set("language", translation);
    checkStatusMaintenance();
  }, []);

  const checkStatusMaintenance = async () => {
    await Action.getStatusMaintenance();
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={RouteName.login} element={<Login />} />
          <Route
            path={RouteName.forgotpassword}
            element={<ForgotPassword />}
          />
          <Route path={RouteName.newpassword} element={<NewPassword />} />
          <Route path={RouteName.twofactorauth} element={<ScanQR />} />
          <Route path={RouteName.authenticationcode} element={<AuthenticatorCode />} />
          <Route path={RouteName.authforgotpassword} element={<AuthForgotPassword />} />
          <Route path={RouteName.twofactorauthpassword} element={<ScanQRPassword />} />
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route path={RouteName.privacy} element={<Privacy />} />
        <Route path={RouteName.contactus} element={<ContactUs />} />
        <Route path={RouteName.freezemode} element={<FreezeMode />} />

        {isAuthenticated !== "" ? (
          <Route element={<AppLayout />}>
            {UseAuth.checkFunction("dashboard-page", "function") ? (
              <Route
                path={RouteName.dashboard}
                element={
                  <Dashboard
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.dashboard
                      );
                    }}
                  />
                }
              />
            ) : null}

            {UseAuth.checkFunction("managament-merchant-page", "function") ||
              UseAuth.checkFunction("managament-admin-page", "function") ||
              UseAuth.checkFunction("managament-customer-page", "function") ||
              UseAuth.checkFunction("managament-merchant-request-page", "function") ||
              UseAuth.checkFunction("managament-transaction-history-page", "function") ||
              UseAuth.checkFunction("management-promo-page", "function") ||
              UseAuth.checkFunction("management-request-promo-page", "function") ||
              UseAuth.checkFunction("managament-product-page", "function") ? (
              <Route
                path={RouteName.management}
                element={
                  <Management
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.management
                      );
                    }}
                  />
                }
              />
            ) : null}

            {UseAuth.checkFunction("generateqr-page", "function") ? (
              <Route
                path={RouteName.generateqr}
                element={
                  <GenerateQR
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.generateqr
                      );
                    }}
                  />
                }
              />
            ) : null}

            {UseAuth.checkFunction("helpcenter-page", "function") ? (
              <Route
                path={RouteName.helpcenter}
                element={
                  <HelpCenter
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.helpcenter
                      );
                    }}
                  />
                }
              />
            ) : null}

            <Route
              path={RouteName.accountsettings}
              element={
                <AccountSettings
                  getBreadcrums={(title) => {
                    Action.getBreadcrums(
                      dispatch,
                      title,
                      RouteName.accountsettings
                    );
                  }}
                />
              }
            />

            {UseAuth.checkFunction("appcustomization-page", "function") ? (
              <Route
                path={RouteName.appcustomization}
                element={
                  <AppCustomization
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(
                        dispatch,
                        title,
                        RouteName.appcustomization
                      );
                    }}
                  />
                }
              />
            ) : null}

            <Route
              path={RouteName.balance}
              element={
                <Balance
                  getBreadcrums={(title) => {
                    Action.getBreadcrums(dispatch, title, RouteName.balance);
                  }}
                />
              } />

            {UseAuth.checkFunction("create-promo-management-promo-button", "button") ||
              UseAuth.checkFunction("update-promo-management-promo-button", "button") ? (
              <Route
                path={RouteName.promo_form}
                element={
                  <PromoForm
                    getBreadcrums={(title) => {
                      Action.getBreadcrums(dispatch, title, RouteName.promo_form);
                    }}
                  />
                } />
            ) : null}

          </Route>
        ) : (
          ""
        )}
      </Routes>
    </BrowserRouter>
  );
};

const Base = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Base;
