import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "TwoFactorAuthenticator",
  initialState: {
    validatePass: false,
    password: "",
    otp: ['', '', '', '', '', ''],
    countDown: 0,
    errorOtp: "",
    qrCode: "",
  },
  reducers: {
    setValidatePass: (state, action) => {
      state.validatePass = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    setCountdown: (state, action) => {
      state.countDown = action.payload;
    },
    setErrorOtp: (state, action) => {
      state.errorOtp = action.payload;
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload;
    },
  },
});

export const {
  setValidatePass,
  setOtp,
  setCountdown,
  setErrorOtp,
  setQrCode,
} = reducerSlice.actions;

export default reducerSlice;
